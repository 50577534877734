/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "@fontsource/barlow/latin-500.css";
import "@fontsource/barlow/latin-700.css";

export { wrapRootElement } from "./scripts/wrap-provider";
require("slick-carousel/slick/slick.css");

export const onClientEntry = () => {
  window.onload = () => {
    setTimeout(() => {
      //addScript(script);
    }, 4000);
  };
};
